import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";

import CssBaseline from "@mui/material/CssBaseline";
import SearchBox from "../../common/components/SearchBox";
import {
  Typography,
  Box,
  Button,
  Menu,
  MenuItem,
  CircularProgress,
  IconButton,
  Badge,
  Divider,
  Tooltip,
} from "@mui/material";
import { connect } from "react-redux";
import { CardContent, Toolbar } from "@mui/material";
import { useHistory } from "react-router-dom";
import {
  getMaternityLeaveReplacements,
  deleteReplacementStaff,
  getPositionsOfReplacement,
} from "../../../../store/maternity-leave/actions";
import ReactPaginate from "react-paginate";
import moment from "moment";
import PreviewReplacementStaffDialog from "../components/PreviewReplacementStaffDialog";
import AddOrUpdateReplacementStaffDialog from "../components/AddOrUpdateReplacementStaffDialog";

import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import VerifiedIcon from "@mui/icons-material/Verified";

const Replacements = (props) => {
  useEffect(() => {
    document.title = "TMIS | Maternity Leave - In Progress";
  }, []);

  const {
    user,
    loading,
    maternityLeaveReplacements,
    getMaternityLeaveReplacements,
    deleteReplacementStaff,
    getPositionsOfReplacement,
    positionsOfReplacement,
  } = props;

  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecods, setTotalRecods] = useState(0);
  const [loader, setLoader] = useState(false);

  const [itemPerPage, setItemPerPage] = useState(
    window.localStorage.getItem("ITEM_PER_PAGE")
      ? +window.localStorage.getItem("ITEM_PER_PAGE")
      : 10
  );

  useEffect(() => {
    if (!positionsOfReplacement.length) getPositionsOfReplacement();
  }, []);

  useEffect(() => {
    const _currentPage = 1;
    setCurrentPage(_currentPage);

    const _searchTerm = "";
    setSearchTerm(_searchTerm);

    const query = {
      searchTerm: _searchTerm,
      page: _currentPage,
      size: itemPerPage,
    };
    getMaternityLeaveReplacements(query, setTotalRecods, setLoader);
  }, [user.selectedEntity, user.selectedAcademicYear]);

  const onSearch = (term) => {
    setSearchTerm(term);

    if (term === "") {
      const _currentPage = 1;
      setCurrentPage(_currentPage);

      const _searchTerm = "";
      setSearchTerm(_searchTerm);

      const query = {
        searchTerm: _searchTerm,
        page: _currentPage,
        size: itemPerPage,
      };
      getMaternityLeaveReplacements(query, setTotalRecods, setLoader);
    }
  };

  const handlePageClick = ({ selected }) => {
    const currentPage = selected + 1;
    setCurrentPage(currentPage);

    const query = {
      searchTerm: searchTerm,
      page: currentPage,
      size: itemPerPage,
    };

    getMaternityLeaveReplacements(query, setTotalRecods, setLoader);
  };

  const [showAddReplacementStaffDialog, setShowAddReplacementStaffDialog] =
    useState(false);

  return (
    <>
      <div className="px-4 pt-2">
        <div className="border rounded">
          <AppBar position="static" elevation={0} className="app-bar w-100">
            <Toolbar
              style={{
                minHeight: "0px",
                borderBottom: "3px solid #e9ecef",
              }}
              className=" my-0 py-1 bg-light"
            >
              <Typography
                variant="p"
                noWrap
                component="div"
                className="d-flex  ilign-items-center text-uppercase text-primary font-weight-bold"
              >
                <span className="text-dark">
                  ({maternityLeaveReplacements.length}) Replacing Staff
                </span>
                {["HEAD_TEACHER"].includes(user?.selectedEntity?.role) && (
                  <Button
                    className="ml-2"
                    disabled={loading}
                    onClick={() => {
                      setShowAddReplacementStaffDialog(true);
                    }}
                    variant="contained"
                    size="small"
                    disableElevation
                    color="primary"
                    style={{
                      borderRadius: "8px",
                      height: "32px",
                      alignItems: "center",
                      fontSize: ".75rem",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      textTransform: "initial",
                    }}
                  >
                    <span className="material-icons">add</span>
                    Add New
                  </Button>
                )}
              </Typography>
              <Box sx={{ flexGrow: 1 }} />

              <Box sx={{ display: { xs: "flex", md: "flex" } }}>
                <SearchBox
                  onSearch={onSearch}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      const query = {
                        searchTerm: searchTerm,
                        page: currentPage,
                        size: itemPerPage,
                      };

                      getMaternityLeaveReplacements(
                        query,
                        setTotalRecods,
                        setLoader
                      );
                    }
                  }}
                  placeholder="Search…"
                />
              </Box>
            </Toolbar>
          </AppBar>
          <div
            style={{
              backgroundColor: "#f3f4f6",
              borderRadius: "0.5rem",
              boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
              MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
              height: "60vh",
              overflowY: "auto",
            }}
            className="px-3"
          >
            {!!maternityLeaveReplacements.length && (
              <table className="table table-striped table-hover">
                <thead>
                  <tr style={{ backgroundColor: "#f0f2f5" }}>
                    <th scope="col">#</th>
                    <th scope="col" className="text-left">
                      Staff Id
                    </th>

                    <th scope="col" className="text-left">
                      Staff Names
                    </th>

                    <th scope="col" className="text-left">
                      Email
                    </th>

                    <th scope="col" className="text-left">
                      Phone
                    </th>

                    <th scope="col" className="text-right">
                      #Positions
                    </th>

                    <th scope="col" className="text-left">
                      Registered On
                    </th>

                    <th scope="col" className="text-left">
                      Registered By
                    </th>

                    <th scope="col" className="text-right">
                      Status
                    </th>

                    {/* <th scope="col" className="text-center">
                      Status On
                    </th> */}

                    {/* <th scope="col" className="text-center">
                      Status By
                    </th> */}

                    <th scope="col" className="text-center">
                      Actions
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {maternityLeaveReplacements.map((replacement) => (
                    <ReplacementItemCard
                      key={replacement.no}
                      replacement={replacement}
                      user={user}
                      loading={loading}
                      deleteReplacementStaff={deleteReplacementStaff}
                    />
                  ))}
                </tbody>
              </table>
            )}

            {!maternityLeaveReplacements?.length && (
              <div className="jumbotron jumbotron-fluid text-center">
                <div className="container py-5 ">
                  <p className="lead">
                    {loading ? "Fetching data..." : "No request made"}{" "}
                  </p>
                </div>
              </div>
            )}
          </div>
          <div
            className="d-flex justify-content-center  align-items-center"
            style={{ overflow: "hidden" }}
          >
            <>
              <div className="mt-3">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel={
                    <>
                      <span className="pr-1 d-none d-md-inline">Next</span>
                      <i className="fas fa-angle-double-right"></i>
                    </>
                  }
                  previousLabel={
                    <>
                      <i className="fas fa-angle-double-left"></i>
                      <span className="pl-1  d-none d-md-inline">Previous</span>
                    </>
                  }
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={1}
                  onPageChange={handlePageClick}
                  pageCount={Math.ceil(totalRecods / itemPerPage)}
                  renderOnZeroPageCount={null}
                  containerClassName="pagination"
                  pageLinkClassName="btn btn-outline-dark btn-sm mr-1 ml-1"
                  previousLinkClassName="btn btn-outline-dark btn-sm mr-1 ml-1"
                  nextLinkClassName="btn btn-outline-dark btn-sm ml-1"
                  activeLinkClassName="active"
                />
              </div>

              {!!maternityLeaveReplacements.length && (
                <select
                  className="custom-select custom-select-sm mr-3 ml-2"
                  style={{ width: "100px" }}
                  value={itemPerPage}
                  onChange={(event) => {
                    const itemPerPage = +event.target.value;
                    window.localStorage.setItem("ITEM_PER_PAGE", itemPerPage);
                    setItemPerPage(itemPerPage);
                    const query = {
                      searchTerm: searchTerm,
                      page: currentPage,
                      size: itemPerPage,
                    };
                    getMaternityLeaveReplacements(
                      query,
                      setTotalRecods,
                      setLoader
                    );
                  }}
                >
                  {[10, 50, 100, 150, 200, 500].map((item, index) => (
                    <option key={index}>{item}</option>
                  ))}
                </select>
              )}
            </>
          </div>
        </div>
      </div>

      {loader && (
        <CircularProgress
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            color: "rgb(7, 142, 206)",
            position: "absolute",
            top: "42%",
            left: "50%",
            marginTop: "-12px",
            marginLeft: "-12px",
          }}
          size={40}
          thickness={4}
        />
      )}

      {showAddReplacementStaffDialog && (
        <AddOrUpdateReplacementStaffDialog
          showDialog={showAddReplacementStaffDialog}
          setShowDialog={setShowAddReplacementStaffDialog}
          replacement={{}}
        />
      )}
    </>
  );
};

const mapStateToProps = ({
  user,
  loading,
  maternityLeaveReplacements,
  positionsOfReplacement,
}) => {
  return { user, loading, maternityLeaveReplacements, positionsOfReplacement };
};

export default connect(mapStateToProps, {
  getMaternityLeaveReplacements,
  deleteReplacementStaff,
  getPositionsOfReplacement,
})(Replacements);

const ReplacementItemCard = (props) => {
  const { replacement, user, deleteReplacementStaff } = props;

  const [showPreviewProfileDialog, setShowPreviewProfileDialog] =
    useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [showConfirmRemove, setShowConfirmRemove] = useState(false);

  return (
    <>
      <tr>
        <th scope="row" className="text-left">
          {replacement.no}
        </th>

        <td className="text-left">{replacement.userId}</td>

        <td className="text-left text-uppercase font-weight-bold">
          {replacement.lastName} {replacement.firstName}
        </td>

        <td className="text-left">{replacement.email}</td>

        <td className="text-left">{replacement.phoneNumber}</td>

        <td className="text-right">
          <span
            className={`font-weight-bold text-${
              replacement.numberOfPositions > 0 ? "primary" : "danger"
            }`}
          >
            {replacement.numberOfPositions} Positions
          </span>
        </td>

        <td className="text-left">
          {moment(replacement.createdOn).format("lll")}
        </td>

        <td className="text-left">
          <div>
            {replacement.createdBy} <br />
            <span className="text-primary font-weight-bold">
              {replacement.schoolName} <i className="fas fa-chevron-right"></i>{" "}
              {replacement.sectorName} / {replacement.districtName}
            </span>
          </div>
        </td>

        <td
          className={`
            text-right 
            ${
              [2, 3].includes(replacement.statusId)
                ? "font-weight-bold text-light"
                : ""
            }
          `}
          style={{
            backgroundColor: `${
              replacement.statusId === 2
                ? "#28a745"
                : replacement.statusId === 3
                ? "#dc3545"
                : "none"
            }`,
          }}
        >
          {replacement.statusName}

          {replacement.statusId !== 1 && (
            <small>
              <br /> {moment(replacement.statusOn).format("lll")}
            </small>
          )}
        </td>

        <td className="text-right">
          {replacement.selected && (
            <Tooltip title="Selected" className="mr-2">
              <VerifiedIcon className="text-success" />
            </Tooltip>
          )}

          <IconButton size="small" onClick={handleOpenMenu}>
            <Badge color="info">
              <span className="material-icons">more_vert</span>
            </Badge>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleCloseMenu}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 2,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 15,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <span>
              <MenuItem
                onClick={() => {
                  handleCloseMenu();
                  setShowPreviewProfileDialog(true);
                }}
                className="text-primary font-weight-bolder"
              >
                <span className="material-icons mr-1">preview</span>
                Preview
              </MenuItem>
              <Divider className="my-1" />
            </span>

            {((replacement.createdById === user.id &&
              replacement.statusId === 1) ||
              ["REB", "RTB"].includes(user.selectedEntity.role)) && (
              <span>
                <MenuItem
                  onClick={(e) => {
                    handleCloseMenu();
                    setShowConfirmRemove(true);
                  }}
                  className="text-danger"
                >
                  <span className="material-icons mr-1">
                    remove_circle_outline
                  </span>
                  Remove
                </MenuItem>
              </span>
            )}
          </Menu>
        </td>
      </tr>

      {showPreviewProfileDialog && (
        <PreviewReplacementStaffDialog
          showDialog={showPreviewProfileDialog}
          setShowDialog={setShowPreviewProfileDialog}
          replacement={replacement}
          closePalentDialog={() => {}}
        />
      )}

      {showConfirmRemove && (
        <ConfirmationDialog
          confirmationDialog={showConfirmRemove}
          message={
            <p className="mt-2 mb-0">
              Are you sure you want to{" "}
              <strong className="text-danger">remove</strong> this staff?
            </p>
          }
          setConfirmationDialog={setShowConfirmRemove}
          onYes={() => {
            deleteReplacementStaff(replacement.id, () =>
              setShowConfirmRemove(false)
            );
          }}
        />
      )}
    </>
  );
};
